// extracted by mini-css-extract-plugin
export var biosection = "BioSection-module--biosection--l8G3C";
export var biosectionLightpurple = "BioSection-module--biosection--lightpurple--2YMAS";
export var biosectionWhite = "BioSection-module--biosection--white--2jz3W";
export var biosectionFirstSection = "BioSection-module--biosection--firstSection--1nWPH";
export var biosectionNoTopSection = "BioSection-module--biosection--noTopSection--3AIKz";
export var biosection__title = "BioSection-module--biosection__title--2ngQS";
export var biosection__imagewrap = "BioSection-module--biosection__imagewrap--3I48T";
export var biosection__content = "BioSection-module--biosection__content--ejnNA";
export var biosection__maxwrap = "BioSection-module--biosection__maxwrap--3MksR";
export var biosection__cta = "BioSection-module--biosection__cta--Nmrdn";
export var slideIn = "BioSection-module--slideIn--1Bdyd";
export var slideInTop = "BioSection-module--slideInTop--3KsO4";
export var slideInNav = "BioSection-module--slideInNav--3Dg15";
export var slideInMobileNav = "BioSection-module--slideInMobileNav--2TLc9";
export var slideOut = "BioSection-module--slideOut--9lyfo";
export var slideOutTop = "BioSection-module--slideOutTop--1478t";
export var fadeOutAnimation = "BioSection-module--fadeOutAnimation--3AJMa";
export var fadeInAnimation = "BioSection-module--fadeInAnimation--3K2LP";