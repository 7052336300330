import React from 'react';
import { Link } from 'gatsby';
import { BLANK, REL } from '@constants';
import { useSourcedLink } from '@hooks';
import { getKeyValue } from '@utils';

const InlineLink =  ({
  externalLink, internalPageLink, internalFileLink, children, className, ...rest
}) => {

  const { path } = internalPageLink || {};
  const { url } = getKeyValue(internalFileLink, 'file') || {};
  const [makeSourcedLink] = useSourcedLink();
  const href = rest.href || makeSourcedLink(path || url || externalLink);

  if (!href) {
    return <React.Fragment />;
  }

  if (href.startsWith('http') || href.startsWith('//') || href.startsWith('mailto') || href.startsWith('tel')) {
    return (
      <a
        target={BLANK}
        rel={REL}
        href={makeSourcedLink(href)}
        className={className}
        {...rest}
      >
        {children}
      </a>
    );
  }

  return (
    <Link
      to={makeSourcedLink(href)}
      className={className}
      {...rest}
    >
      {children}
    </Link>
  );
};

export default InlineLink;
