import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import AnimateHeight from 'react-animate-height';
import { useInView } from 'react-intersection-observer';
import { AnchorLink } from 'gatsby-plugin-anchor-links';

import * as styles from './ChairToc.module.scss';

const ChairToc = ({ items, title = "Our Team", activeId }) => {
  const [menuExpanded, setMenuExpanded] = useState(true);

  const { ref, inView } = useInView({
    threshold: 1,
    rootMargin: '-100px 0px 400px',
    initialInView: false,
  });

  useEffect(() => {
    if(window.innerWidth < 768) {
      setMenuExpanded(inView);
    }
  }, [inView]);

  return(
    <div
      ref={ref} id="sticky"
      className={styles.chairtoc__left}
    >
      <nav className={cx(styles.chairtoc__nav)}>
        <h3 className={cx(styles.chairtoc__title, {
          [styles.chairtoc__titleExpanded]: menuExpanded,
          })}
        >
          {title}
          <button
            onClick={() => setMenuExpanded(!menuExpanded)}
            className={cx(styles.chairtoc__titleButton, {
              [styles.chairtoc__titleButtonExpanded]: menuExpanded,
            })}
          >
            +
          </button>
        </h3>
        <AnimateHeight
          duration={1000}
          delay={300}
          height={menuExpanded ? 'auto' : 0}
          style={{ width: '100%' }}
        >
          <ul className={styles.chairtoc}>
            {items.map((chair) => {
              const { firstName, lastName, contentful_id } = chair;
              return (
                <li
                  key={contentful_id}
                  className={cx(styles.chairtoc__item, {
                    [styles.chairtoc__itemActive]: activeId === `contentful-${contentful_id}`,
                  })}
                >
                  <AnchorLink to={`#contentful-${contentful_id}`}>
                    {`${firstName} ${lastName}`}
                  </AnchorLink>
                </li>
              );
            })}
          </ul>
        </AnimateHeight>
      </nav>
    </div>
  );
};

export default ChairToc;
