import React from 'react';
import cx from 'classnames';
import { GatsbyImage } from 'gatsby-plugin-image';
import { BLANK, NO_OPENER } from '@constants';
import { useSourcedLink } from '@hooks';
import { InlineLink } from '@atoms';
import { BasicRichText } from '@molecules';
import { capitalizeFirstLetter } from '@utils';

import * as styles from './HomepageHero.module.scss';
import Facebook from '../../../assets/facebook.inline.svg';
import Instagram from '../../../assets/instagram.inline.svg';
import Youtube from '../../../assets/youtube.inline.svg';
import Twitter from '../../../assets/twitter.inline.svg';

const HomepageHero = ({
  title, subheading, image, action, slogan, social,
}) => {
  const splitString = title && title.split(" ")
  const lastWordInTitle = splitString.pop();
  const splitTitle = splitString.join(' ').replace(/\u2028/g, '').split('\n');
  const splitTitleLength = splitTitle.length;
  const [makeSourcedLink] = useSourcedLink();
  const socialIcons = {
    facebook: Facebook,
    youtube: Youtube,
    instagram: Instagram,
    twitter: Twitter,
  };

  return (
    <div className={styles.homepageHero}>
      <div className={styles.homepageHero__content}>
        <div className={styles.homepageHero__inner}>
          <div className={styles.homepageHero__text}>
            <div>
              <h1 className={styles.homepageHero__title}>
                {splitTitle.map((t, i) => (
                  <span
                      // eslint-disable-next-line react/no-array-index-key
                    key={`${t}-${i}`}
                  >
                    {t}
                    {i === splitTitleLength - 1 ? (
                      <React.Fragment>
                        {" "}
                        <span>
                          {lastWordInTitle}
                        </span>
                      </React.Fragment>
                    ) : <br className={styles.homepageHero__desktopbreak} />}
                    {t.includes(".") && (
                      <br className={styles.homepageHero__mobilebreak} />
                    )}
                  </span>
                ))}
              </h1>
              <div className={styles.homepageHero__textdesktop}>
                <BasicRichText content={subheading} color="white" size="small" />
                <InlineLink {...action} className={styles.homepageHero__cta}>{action.linkTitle}</InlineLink>
              </div>
            </div>
            {social && (
              <ul className={cx(styles.homepageHero__sociallinks, styles.homepageHero__sociallinksDesktop)}>
                {social.map((account) => {
                  const skey = (account.key).toLowerCase();
                  const Icon = socialIcons[skey];
                  return (
                    <li key={`${account.key}-li`}>
                      <a
                        className={cx(styles.homepageHero__sociallink, styles[`homepageHero__sociallink${capitalizeFirstLetter(skey)}`])}
                        href={makeSourcedLink(account.value)}
                        target={BLANK}
                        rel={NO_OPENER}
                      >
                        <Icon title={skey} id={skey} />
                      </a>
                    </li>
                  );
                  })}
              </ul>
            )}
          </div>
          {image && (
            <GatsbyImage
              image={image.gatsbyImageData}
              className={styles.homepageHero__image}
              objectFit="cover"
              layout="fullWidth"
              loading="eager"
              objectPosition="50% 50%"
              alt={image.description}
            />
          )}
        </div>
        <div className={styles.homepageHero__textmobile}>
          <div>
          <BasicRichText content={subheading} color="white" size="small" />
            {social && (
              <ul className={cx(styles.homepageHero__sociallinks, styles.homepageHero__sociallinksMobile)}>
                {social.map((account) => {
                  const skey = (account.key).toLowerCase();
                  const Icon = socialIcons[skey];
                  return (
                    <li key={`${account.key}-li`}>
                      <a
                        className={cx(styles.homepageHero__sociallink, styles[`homepageHero__sociallink${capitalizeFirstLetter(skey)}`])}
                        href={makeSourcedLink(account.value)}
                        target={BLANK}
                        rel={NO_OPENER}
                      >
                        <Icon title={skey} id={skey} />
                      </a>
                      </li>
                    );
                    })}
                </ul>
            )}
          </div>
          <InlineLink {...action} className={styles.homepageHero__cta}>{action.linkTitle}</InlineLink>
        </div>
          <p
            className={styles.homepageHero__slogan}
            dangerouslySetInnerHTML={{__html: slogan.replace(/!/g, `<span class=${styles.homepageHero__sloganExclamation}>!</span>`)}}
          />
      </div>
    </div>
  );
};

export default HomepageHero;
