// extracted by mini-css-extract-plugin
export var transitionteamsection = "TransitionTeamSection-module--transitionteamsection--FfQ7q";
export var transitionteamsectionLightpurple = "TransitionTeamSection-module--transitionteamsection--lightpurple--3xbxk";
export var transitionteamsectionWhite = "TransitionTeamSection-module--transitionteamsection--white--1ZPrL";
export var transitionteamsectionFirstSection = "TransitionTeamSection-module--transitionteamsection--firstSection--2lfdB";
export var transitionteamsection__maxwrap = "TransitionTeamSection-module--transitionteamsection__maxwrap--ZDbiK";
export var transitionteamsection__containerNoBio = "TransitionTeamSection-module--transitionteamsection__container--noBio--SEA5C";
export var transitionteamsection__container = "TransitionTeamSection-module--transitionteamsection__container--18zcw";
export var transitionteamsection__title = "TransitionTeamSection-module--transitionteamsection__title--JIYq7";
export var transitionteamsection__titleNoBio = "TransitionTeamSection-module--transitionteamsection__title--noBio--1RojN";
export var transitionteamsection__persondetails = "TransitionTeamSection-module--transitionteamsection__persondetails--3VZQB";
export var transitionteamsection__person = "TransitionTeamSection-module--transitionteamsection__person--fWgTM";
export var transitionteamsection__name = "TransitionTeamSection-module--transitionteamsection__name--1Bnwg";
export var transitionteamsection__team = "TransitionTeamSection-module--transitionteamsection__team--GY8r-";
export var transitionteamsection__position = "TransitionTeamSection-module--transitionteamsection__position--3iBpC";
export var transitionteamsection__image = "TransitionTeamSection-module--transitionteamsection__image--1zbhI";
export var transitionteamsection__link = "TransitionTeamSection-module--transitionteamsection__link--1Jf13";
export var slideIn = "TransitionTeamSection-module--slideIn--3f9sS";
export var slideInTop = "TransitionTeamSection-module--slideInTop--3UE9i";
export var slideInNav = "TransitionTeamSection-module--slideInNav--3f-Xi";
export var slideInMobileNav = "TransitionTeamSection-module--slideInMobileNav--1_qWz";
export var slideOut = "TransitionTeamSection-module--slideOut--5ehgn";
export var slideOutTop = "TransitionTeamSection-module--slideOutTop--35XCL";
export var fadeOutAnimation = "TransitionTeamSection-module--fadeOutAnimation--S1e8-";
export var fadeInAnimation = "TransitionTeamSection-module--fadeInAnimation--2rd3_";