import React, { useEffect, useContext } from 'react';
import cx from 'classnames';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useInView } from 'react-intersection-observer';
import { BasicRichText } from '@molecules';

import ModalContext from '../../../context/ModalContext';
import * as styles from './SimpleHero.module.scss';

const SimpleHero = ({
  title, images, heroColor, intro, date, noSticky,
}) => {
  const imageCount = images ? images.length : 0;
  const {
    toggleSticky,
  } = useContext(ModalContext);
  const [titleRef, titleInview] = useInView({ triggerOnce: false, rootMargin: '100px 0px', initialInView: true });

  useEffect(() => {
    if(!noSticky) {
      toggleSticky(!titleInview);
    }
  }, [titleInview, toggleSticky]);

  return (
    <div className={cx(styles.simplehero, {
        [styles.simpleheroLightPurple]: heroColor === 'lightpurple',
        [styles.simpleheroLightBlue]: heroColor === 'lightblue',
        [styles.simpleheroDarkPurple]: heroColor === 'darkpurple',
        [styles.simpleheroZero]: imageCount === 0,
      })}
    >
      <div className={styles.simplehero__maxwrap}>
        <div className={styles.simplehero__gutters} ref={titleRef}>
          <h1 className={cx(styles.simplehero__title, {
              [styles.simplehero__titleZero]: imageCount === 0,
              [styles.simplehero__titleDarkPurple]: heroColor === 'darkpurple',
            })}
          >
            {date && (
              <span className={styles.simplehero__date}>{date}</span>
            )}
            {title}
          </h1>
          {intro && (
            <div className={styles.content__intro}>
              <BasicRichText content={intro} size="large" color={heroColor === "darkpurple" ? "white" : "navy"} />
            </div>
          )}
          {images && (imageCount === 6 || imageCount === 1) && (
            <div className={cx(styles.simplehero__images, {
              [styles.simplehero__imagesOne]: imageCount === 1,
              [styles.simplehero__imagesSix]: imageCount === 6,
            })}>
              {images.map((image, i) => {
                if(i === 1 || i === 4) {
                  return(
                    <div className={cx(styles.simplehero__column, {
                      [styles.simplehero__columnOne]: imageCount === 1,
                      [styles.simplehero__columnSix]: imageCount === 6,
                    })}>
                      <GatsbyImage
                        image={image.gatsbyImageData}
                        className={styles.simplehero__image}
                        objectFit="cover"
                        layout="fullWidth"
                        loading="eager"
                        objectPosition="50% 50%"
                        alt={image.description}
                      />
                      {images[i + 1] && (
                        <GatsbyImage
                          image={images[i + 1].gatsbyImageData}
                          className={styles.simplehero__image}
                          objectFit="cover"
                          layout="fullWidth"
                          loading="eager"
                          objectPosition="50% 50%"
                          alt={images[2].description}
                        />
                      )}
                    </div>
                  );
                } else if(i === 2 || i === 5 || i > 5) {
                  return <React.Fragment />;
                }
                return (
                  <div className={cx(styles.simplehero__column, {
                    [styles.simplehero__columnOne]: imageCount === 1,
                    [styles.simplehero__columnSix]: imageCount === 6,
                  })}>
                    {image && (
                      <GatsbyImage
                        image={image.gatsbyImageData}
                        className={styles.simplehero__image}
                        objectFit="cover"
                        layout="fullWidth"
                        loading="eager"
                        objectPosition="50% 50%"
                        alt={image.description}
                      />
                    )}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SimpleHero;
