// extracted by mini-css-extract-plugin
export var calltoactionsection = "CallToActionSection-module--calltoactionsection--RmWL5";
export var calltoactionsectionLightpurple = "CallToActionSection-module--calltoactionsection--lightpurple--1ya1m";
export var calltoactionsectionWhite = "CallToActionSection-module--calltoactionsection--white--29bGA";
export var calltoactionsectionNoTopSection = "CallToActionSection-module--calltoactionsection--noTopSection--2h_KV";
export var calltoactionsectionLightblue = "CallToActionSection-module--calltoactionsection--lightblue--3eYEB";
export var calltoactionsectionFirstSection = "CallToActionSection-module--calltoactionsection--firstSection--3lxNq";
export var calltoactionsection__gutters = "CallToActionSection-module--calltoactionsection__gutters--XVpIh";
export var calltoactionsection__title = "CallToActionSection-module--calltoactionsection__title--7QLJC";
export var calltoactionsection__text = "CallToActionSection-module--calltoactionsection__text--1wKwI";
export var calltoactionsection__content = "CallToActionSection-module--calltoactionsection__content--3_6MT";
export var calltoactionsection__maxwrap = "CallToActionSection-module--calltoactionsection__maxwrap---h36h";
export var calltoactionsection__image = "CallToActionSection-module--calltoactionsection__image--1PFnn";
export var calltoactionsection__cta = "CallToActionSection-module--calltoactionsection__cta--3DiL5";
export var slideIn = "CallToActionSection-module--slideIn--1k6C3";
export var slideInTop = "CallToActionSection-module--slideInTop--l17yL";
export var slideInNav = "CallToActionSection-module--slideInNav--to14J";
export var slideInMobileNav = "CallToActionSection-module--slideInMobileNav--2TwG1";
export var slideOut = "CallToActionSection-module--slideOut--iW0iV";
export var slideOutTop = "CallToActionSection-module--slideOutTop--26Cc5";
export var fadeOutAnimation = "CallToActionSection-module--fadeOutAnimation--1SCNi";
export var fadeInAnimation = "CallToActionSection-module--fadeInAnimation--1IDB4";