// extracted by mini-css-extract-plugin
export var inlineimage__wrapper = "InlineImage-module--inlineimage__wrapper--11vuq";
export var inlineimage__caption = "InlineImage-module--inlineimage__caption--2nO7B";
export var slideIn = "InlineImage-module--slideIn--1vJJ3";
export var slideInTop = "InlineImage-module--slideInTop--1wLbX";
export var slideInNav = "InlineImage-module--slideInNav--2Exhx";
export var slideInMobileNav = "InlineImage-module--slideInMobileNav--2OeoD";
export var slideOut = "InlineImage-module--slideOut--VcNSN";
export var slideOutTop = "InlineImage-module--slideOutTop--3LwiM";
export var fadeOutAnimation = "InlineImage-module--fadeOutAnimation--2V_M0";
export var fadeInAnimation = "InlineImage-module--fadeInAnimation--33MbK";