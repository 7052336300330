import React from 'react';
import cx from 'classnames';
import { TRANSITION_TEAM } from '@constants';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Cta } from '@atoms';
import { BasicRichText } from '@molecules';

import * as styles from './CommunitySurveySection.module.scss';

const CommunitySurveySection = (props) => {
  const {
    title, description, link, sectionImagery, firstSection, previousSection, backgroundTheme,
  } = props;
  const prevSectType = previousSection && previousSection.sectionType;
  const { linkTitle, ...rest } = link || {};

  return (
    <section
      className={cx(styles.communitysurveysection, {
          [styles.communitysurveysectionFirstSection]: firstSection,
          [styles.communitysurveyNoTopSection]: prevSectType === TRANSITION_TEAM,
          [styles.communitysurveyLightpurple]: backgroundTheme === 'lightpurple',
          [styles.communitysurveyWhite]: backgroundTheme === 'white',
        })}
    >
      <div className={styles.communitysurveysection__maxwrap}>
        <div className={styles.communitysurveysection__content}>
          <div className={styles.communitysurveysection__header}>
            {title && (
              <h2 className={styles.communitysurveysection__title}>{title.title}</h2>
            )}
            {description && (
              <BasicRichText content={description} color="navy" size="small"  />
            )}
            {linkTitle && (
              <p><Cta className={styles.communitysurveysection__cta} {...rest}>{linkTitle}</Cta></p>
            )}
          </div>
          {sectionImagery && (
            <div className={styles.communitysurveysection__imagewrap}>
              {sectionImagery.map((image, i) => {
                if(i === 0 || i === 1) {
                  return (
                    <GatsbyImage
                      image={image.gatsbyImageData}
                      className={styles.communitysurveysection__image}
                      objectFit="contain"
                    />
                  );
                } else {
                  return <React.Fragment />;
                }
              })}
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default CommunitySurveySection;
