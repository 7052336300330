import React from 'react';
import cx from 'classnames';
import { BLANK, REL } from '@constants';
import { useSourcedLink } from '@hooks';
import { getKeyValue } from '@utils';
import { Link } from 'gatsby';
import Underline from '../../../assets/underline.inline.svg';
import * as styles from './Cta.module.scss';

const Cta = ({
  externalLink, internalPageLink, internalFileLink, children, className, ...rest
}) => {
  const { path } = internalPageLink || {};
  const { url } = getKeyValue(internalFileLink, 'file') || {};
  const [makeSourcedLink] = useSourcedLink();
  const href = makeSourcedLink(path || url || externalLink);

  if (!href) {
    return <React.Fragment />;
  }

  if (href.startsWith('http') || href.startsWith('//')) {
    return (
      <a
        target={BLANK}
        rel={REL}
        href={makeSourcedLink(href)}
        {...rest}
        className={cx(styles.cta, className)}
      >
        {children}
        <Underline />
      </a>
    );
  }

  return (
    <Link className={cx(styles.cta, className)} to={makeSourcedLink(href)} {...rest}>
      {children}
        <Underline />
    </Link>
  );
};

export default Cta;
