import React from 'react';
import cx from 'classnames';
import { GatsbyImage } from 'gatsby-plugin-image';
import { InlineLink } from '@atoms';

import Circle from '../../../assets/circle.inline.svg';
import * as styles from './TransitionTeamSection.module.scss';

const TransitionTeamSection = (props) => {
  const {
    title, description, link, contentReferences, firstSection, backgroundTheme,
  } = props;
  const { linkTitle, ...rest } = link || {};

  return (
    <section
      className={cx(styles.transitionteamsection, {
        [styles.transitionteamsectionFirstSection]: firstSection,
        [styles.transitionteamsectionLightpurple]: backgroundTheme === 'lightpurple',
        [styles.transitionteamsectionWhite]: backgroundTheme === 'white',
      })}
    >
      <div className={styles.transitionteamsection__maxwrap}>
        <div className={styles.transitionteamsection__container}>
          {title && (
            <h2 className={styles.transitionteamsection__title}>
              <Circle />
              {title.title}
            </h2>
          )}
          {contentReferences && (
            <ul className={styles.transitionteamsection__team}>
              {contentReferences.map((item, i) => {
                const { title, firstName, lastName, headshot, fullBioLink } = item;
                const { linkTitle, ...rest } = fullBioLink || {};
                return (
                  <li className={styles.transitionteamsection__person}>
                    <GatsbyImage
                      className={styles.transitionteamsection__image}
                      image={headshot.gatsbyImageData}
                    />
                    <div className={styles.transitionteamsection__persondetails}>
                      <span className={styles.transitionteamsection__name}>
                        {firstName}
                        {' '}
                        {lastName}
                      </span>
                      <span className={styles.transitionteamsection__position}>
                        {title}
                      </span>
                      {fullBioLink && (
                        <InlineLink className={styles.transitionteamsection__link} {...rest}>
                          {linkTitle}
                        </InlineLink>
                      )}
                    </div>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      </div>
    </section>
  );
};

export default TransitionTeamSection;
