// extracted by mini-css-extract-plugin
export var pullquote__wrapper = "PullQuote-module--pullquote__wrapper--1VMkX";
export var pullquote__wrapperContentDetail = "PullQuote-module--pullquote__wrapper--contentDetail--3Kjts";
export var pullquote__cite = "PullQuote-module--pullquote__cite--3R-yd";
export var pullquote__citePurple = "PullQuote-module--pullquote__cite--purple--1EaT6";
export var slideIn = "PullQuote-module--slideIn--2E4vC";
export var slideInTop = "PullQuote-module--slideInTop--3a28E";
export var slideInNav = "PullQuote-module--slideInNav--3tqST";
export var slideInMobileNav = "PullQuote-module--slideInMobileNav--2MxP3";
export var slideOut = "PullQuote-module--slideOut--jHugn";
export var slideOutTop = "PullQuote-module--slideOutTop--MxwC6";
export var fadeOutAnimation = "PullQuote-module--fadeOutAnimation--2AInU";
export var fadeInAnimation = "PullQuote-module--fadeInAnimation--3llA6";