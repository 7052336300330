import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { BasicRichText } from '@molecules';

import * as styles from './InlineImage.module.scss';

const InlineImage = ({
  image, caption,
}) => {

  return (
    <div className={styles.inlineimage__wrapper}>
      <GatsbyImage
        image={image.gatsbyImageData}
        className={styles.inlineimage__image}
        objectFit="cover"
        layout="fullWidth"
        loading="eager"
        objectPosition="50% 50%"
        alt={image.description}
      />
      {caption && (
        <div className={styles.inlineimage__caption}>
          <BasicRichText content={caption} size="xsmall" color="navy" />
        </div>
      )}
    </div>
  );
};

export default InlineImage;
