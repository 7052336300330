import React , { useEffect, useState } from 'react';
import cx from 'classnames';
import { GatsbyImage } from 'gatsby-plugin-image';
import { BasicRichText } from '@molecules';

import * as styles from './Appointee.module.scss';

const Appointee = ({ firstName, headshot, lastName, shortBio, title, contentful_id, activeId }) => {

  return (
    <div
      id={`contentful-${contentful_id}`}
      className={cx(styles.appointee, {
        [styles.appointeeActive]: activeId === `contentful-${contentful_id}`,
      })}
    >
      {headshot && (
        <div className={styles.appointee__bg}>
          <GatsbyImage
            image={headshot.gatsbyImageData}
            className={styles.appointee__image}
            objectFit="cover"
            layout="fullWidth"
            loading="eager"
            objectPosition="50% 50%"
            alt={headshot.description}
          />
        </div>
      )}
      <div className={styles.appointee__group}>
        <h3 className={styles.appointee__name}>
          {`${firstName} ${lastName}`}
          {title && (
            <span className={styles.appointee__title}>{title}</span>
          )}
        </h3>
        {shortBio && shortBio.raw && (
          <BasicRichText content={shortBio} size="small" color="navy" />
        )}
      </div>
    </div>
  );
};

export default Appointee;
