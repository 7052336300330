import React from 'react';
import { BasicRichText } from '@molecules';

import * as styles from './Content.module.scss';

const Content = ({
  title, intro, body, pageType, date, sharedLabels,
}) => {

  return (
    <div className={styles.content}>
      <div className={styles.content__maxwrap}>
        <div className={styles.content__gutters}>
          {title && (
            <h1 className={styles.content__title}>
              {date && (
                <span className={styles.content__date}>{date}</span>
              )}
              {title}
            </h1>
          )}
          {intro && (
            <div className={styles.content__intro}>
              <BasicRichText content={intro} size="large" color="navy" pageType={pageType} sharedLabels={sharedLabels}/>
            </div>
          )}
          {body && (
            <BasicRichText content={body} size="medium" color="navy" pageType={pageType} sharedLabels={sharedLabels}/>
          )}
        </div>
      </div>
    </div>
  );
};

export default Content;
