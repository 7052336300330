import React from 'react';
import cx from 'classnames';
import { GatsbyImage } from 'gatsby-plugin-image';
import { BLANK, NO_OPENER } from '@constants';
import { useSourcedLink } from '@hooks';
import { InlineLink } from '@atoms';
import { BasicRichText } from '@molecules';
import { capitalizeFirstLetter } from '@utils';

import Logo from '../../../assets/mwmelogo_dark.inline.svg';
import UpstatementLogo from '../../../assets/upstatement.inline.svg';
import * as styles from './Footer.module.scss';

const Footer = (props) => {
  const {
    homepage, footerLinks, copyright, footerColumnTwo, footerColumnThree,
    paidForDisclaimer, socialLinks, footerCallToAction, footerPullQuote,
    footerBackground,
  } = props;
  const hpLink = homepage ? homepage[0] : {};
  const themeAppend = footerBackground && capitalizeFirstLetter(footerBackground.replace(/\s/g, ''));
  const [makeSourcedLink] = useSourcedLink();

  return (
    <footer className={cx(styles.footer, {
      [styles[`footer${themeAppend}`]]: themeAppend,
    })}>
      <div className={styles.footer__border} />
      <div className={styles.footer__gutter}>
        <div className={styles.footer__content}>
          <ul className={cx(styles.footer__contentrow, styles.footer__contentrowOne)}>
            <li className={styles.footer__logo}>
              <Logo aria-label={hpLink.key} />
            </li>
            {footerPullQuote && (
              <li className={styles.footer__quotewrap}>
                <blockquote className={styles.footer__quote}>
                  <BasicRichText content={footerPullQuote.quote} size="small" color="white" />
                  <cite className={styles.footer__citation}>
                    <span className={styles.footer__citationText}>
                      {footerPullQuote.citationText}
                    </span>
                    <GatsbyImage
                      image={footerPullQuote.citationSignatureImage.gatsbyImageData}
                      description={footerPullQuote.citationSignatureImage.description}
                      className={styles.footer__citationImage}
                    />
                  </cite>
                </blockquote>
              </li>
            )}
            {footerCallToAction && (
              <li className={styles.footer__ctawrap} key={footerCallToAction.id}>
                <InlineLink
                  {...footerCallToAction}
                  className={styles.footer__cta}
                >
                  {footerCallToAction.linkTitle}
                </InlineLink>
              </li>
            )}
          </ul>
          <div className={cx(styles.footer__contentrow)}>
            {footerLinks && (
              <ul className={styles.footer__footerlinks}>
                {footerLinks.map((link) => {
                  const { linkTitle, ...rest } = link;
                  return (
                    <li key={link.id}>
                      <InlineLink {...rest} className={styles.footer__footerlink}>
                        {linkTitle}
                      </InlineLink>
                    </li>
                  );
                })}
              </ul>
            )}
            <div className={styles.footer__coltwo}>
              <div className={styles.footer__twocol}>
                {footerColumnTwo && (
                  <div className={styles.footer__col}>
                    <h2 className={styles.footer__coltitle}>
                      {footerColumnTwo.title}
                    </h2>
                    {footerColumnTwo.description && (
                      <BasicRichText content={footerColumnTwo.description} size="xsmall" color="white" />
                    )}
                    {footerColumnTwo.link && (
                      <InlineLink {...footerColumnTwo.link}
                        className={cx(styles.footer__footerlink, styles.footer__footerlinkColtwo)}
                      >
                        {footerColumnTwo.link.linkTitle}
                      </InlineLink>
                    )}
                  </div>
                )}
                {footerColumnThree && (
                  <div className={styles.footer__col}>
                    <h2 className={styles.footer__coltitle}>
                      {footerColumnThree.title}
                    </h2>
                    {footerColumnThree.description && (
                      <BasicRichText content={footerColumnThree.description} size="xsmall" color="white" />
                    )}
                    {footerColumnThree.link && (
                      <InlineLink {...footerColumnThree.link}
                        className={cx(styles.footer__footerlink, styles.footer__footerlinkColtwo)}
                      >
                        {footerColumnThree.link.linkTitle}
                      </InlineLink>
                    )}
                  </div>
                )}
              </div>
            </div>
            {socialLinks && (
              <ul className={styles.footer__sociallinks}>
                {socialLinks.map((social) => (
                  <li key={social.value} className={styles.footer__sociallinkitem}>
                    <a
                      className={`${styles.footer__sociallink}`}
                      href={makeSourcedLink(social.value)}
                      target={BLANK}
                      rel={NO_OPENER}
                    >
                      {social.key}
                    </a>
                  </li>
                ))}
              </ul>
            )}
          </div>
          <ul className={cx(styles.footer__contentrow, styles.footer__contentrowThree)}>
            <li className={cx(styles.footer__copyright,styles.footer__upstatement)}>
                Designed by Michelle’s friends at{' '}
              <span className={styles.footer__upstatementLogo}>
                <a href="https://www.upstatement.com/">
                  <UpstatementLogo />
                  <span className={styles.footer__visuallyhidden}>Upstatement</span>
                </a>
              </span>
            </li>
            {paidForDisclaimer && (
              <li
                key={paidForDisclaimer}
                className={styles.footer__disclaimer}
              >
                {paidForDisclaimer}
              </li>
            )}
            {copyright && (
              <li
                className={styles.footer__copyright}
                key={styles.footer__copyright}
              >
                {copyright}
              </li>
            )}
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
