// extracted by mini-css-extract-plugin
export var chairtoc = "ChairToc-module--chairtoc--2TdjS";
export var chairtoc__item = "ChairToc-module--chairtoc__item--3NQ6t";
export var chairtoc__itemActive = "ChairToc-module--chairtoc__item--active--2Nm9D";
export var chairtoc__left = "ChairToc-module--chairtoc__left--1m7GD";
export var chairtoc__right = "ChairToc-module--chairtoc__right--10n7-";
export var chairtoc__title = "ChairToc-module--chairtoc__title--cX6i_";
export var chairtoc__titleExpanded = "ChairToc-module--chairtoc__title--expanded--2FJQl";
export var chairtoc__titleButton = "ChairToc-module--chairtoc__title--button--3wkTN";
export var chairtoc__titleButtonExpanded = "ChairToc-module--chairtoc__title--button--expanded--1j4ud";
export var chairtoc__nav = "ChairToc-module--chairtoc__nav--3rHjW";
export var chairtoc__gutters = "ChairToc-module--chairtoc__gutters--2tkd6";
export var slideIn = "ChairToc-module--slideIn--1-_j5";
export var slideInTop = "ChairToc-module--slideInTop--29FmA";
export var slideInNav = "ChairToc-module--slideInNav--1Y9Ya";
export var slideInMobileNav = "ChairToc-module--slideInMobileNav--3UWwz";
export var slideOut = "ChairToc-module--slideOut--1tlrg";
export var slideOutTop = "ChairToc-module--slideOutTop--10jEK";
export var fadeOutAnimation = "ChairToc-module--fadeOutAnimation--UqUVU";
export var fadeInAnimation = "ChairToc-module--fadeInAnimation--3RPUd";