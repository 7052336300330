import React, { useState, useContext } from 'react';
import { useInView } from 'react-intersection-observer';
import cx from 'classnames';
import AnimateHeight from 'react-animate-height';
import { DARK, TRANSPARENT, BLANK } from '@constants';
import { useSourcedLink } from '@hooks';
import { getKeyValue } from '@utils';

import ModalContext from '../../../context/ModalContext';
import LogoAccent from '../../../assets/mwmelogo_dark.inline.svg';
import Logo from '../../../assets/mwmelogo_dark.inline.svg';
import LogoPurple from '../../../assets/mwmelogo_lightpurple.inline.svg';
import LogoBlue from '../../../assets/mwmelogo_lightblue.inline.svg';
import * as styles from './Navigation.module.scss';

const Navigation = (props) => {
  const {
    navTheme, homepageLink, animate, navigation, otherLangs, currentLanguage,
    topNavigationMenuLabel: menuLabel, heroLogo = 'dark'
  } = props;

  console.log(homepageLink)
  const [menuExpanded, setMenuExpanded] = useState(false);
  const [makeSourcedLink] = useSourcedLink();
  const { sticky } = useContext(ModalContext);
  const [ref,
    inView] = useInView({ triggerOnce: true, rootMargin: '0px 0px' });
  const { path: homepagePath } = homepageLink || {};

  return (
    <header
      className={cx(styles.header, {
        [styles.headerCollapsed]: !menuExpanded,
        [styles.headerExpanded]: menuExpanded,
        [styles.headerSticky]: sticky,
        [styles.headerDark]: navTheme === DARK,
        [styles.headerTransparent]: navTheme === TRANSPARENT,
      })}
    >
      <nav
        ref={ref}
        className={cx(styles.navigation, {
          [styles.navigationAnimate]: animate,
          [styles.navigationAnimateInview]: inView,
          [styles.navigationDark]: navTheme === DARK,
        })}
      >
        <a
          className={cx(styles.navigation__logo, {
            [styles.navigation__logoSticky]: sticky,
          })}
          key={homepageLink.id}
          aria-label={homepageLink.label}
          href={makeSourcedLink(homepagePath || '/')}
        >
          {!sticky ? (
            <React.Fragment>
              {heroLogo === 'lightpurple' && (
                <React.Fragment>
                  <LogoPurple />
                </React.Fragment>
              )}
              {heroLogo === 'lightblue' && (
                <React.Fragment>
                  <LogoBlue />
                </React.Fragment>
              )}
              {heroLogo === 'dark' && (
                <React.Fragment>
                  <Logo />
                </React.Fragment>
              )}
            </React.Fragment>
          ) : (<LogoAccent />)}
        </a>
        <div className={styles.navigation__mob}>
        <ul
          className={cx(styles.navigation__list,
            styles.navigation__listDesktop,
            styles.navigation__listLang, {
            [styles.navigation__listExpanded]: menuExpanded,
            [styles.navigation__listCollapsed]: !menuExpanded,
            [styles.navigation__listSticky]: sticky,
          })}
          id="menudesktop"
        >
          {otherLangs && currentLanguage && (
            <React.Fragment>
              <li
                className={cx(styles.navigation__lang, styles.navigation__langActive, {
                  [styles.navigation__langSticky]: sticky,
                  [styles.navigation__langTransparent]: navTheme === TRANSPARENT,
                })}
                key={`${currentLanguage.label}-${currentLanguage.label}`}
              >
                <span>{currentLanguage.label}</span>
              </li>
              {otherLangs.map((lang) => (
                <li
                  className={cx(styles.navigation__lang, {
                    [styles.navigation__langSticky]: sticky,
                    [styles.navigation__langTransparent]: navTheme === TRANSPARENT,
                  })}
                  key={`${lang.path}-${lang.label}`}
                >
                  <a href={makeSourcedLink(lang.path)}>
                    <span>{lang.label}</span>
                  </a>
                </li>
              ))}
            </React.Fragment>
          )}
        </ul>
          <button
            id="toggle"
            aria-expanded={menuExpanded}
            className={cx(styles.navigation__hamburger, {
              [styles.navigation__hamburgerSticky]: sticky,
              [styles.navigation__hamburgerExpanded]: menuExpanded,
            })}
            type="button"
            onClick={() => setMenuExpanded(!menuExpanded)}
          >
            <div
              className={cx(styles.navigation__hamburger, {
                [styles.navigation__hamburgerSticky]: sticky,
              })}
            >
              <div className={styles.navigation__hamburgerbox}>
                <div
                  className={cx(styles.navigation__hamburgerinner, {
                    [styles.navigation__hamburgerinnerActive]: menuExpanded,
                  })}
                >
                  {!menuExpanded ? menuLabel : "Close"}
                </div>
              </div>
            </div>
          </button>
        </div>
        <ul
          className={cx(styles.navigation__list, styles.navigation__listMobile, {
            [styles.navigation__listExpanded]: menuExpanded,
            [styles.navigation__listCollapsed]: !menuExpanded,
            [styles.navigation__listSticky]: sticky,
          })}
          id="menu"
          aria-hidden={menuExpanded}
        >
          <AnimateHeight
            duration={300}
            delay={300}
            height={menuExpanded ? 'auto' : 0}
            style={{ width: '100%' }}
          >
            {navigation && navigation.map((nav, i) => {
              const { externalLink } = nav;
              const { path } = getKeyValue(nav, 'internalPageLink') || {};
              const { url } = getKeyValue(nav, 'internalFileLink.file') || {};
              const linkProps = {
                href: makeSourcedLink(path || url || externalLink),
              };
              if (externalLink) {
                linkProps.target = BLANK;
              }
              return (
                <li
                  key={`${nav.id}-mobile`}
                  className={cx(styles.navigation__item, {
                    [styles.navigation__itemSticky]: sticky,
                  })}
                >
                  <a {...linkProps}>
                    {nav.label}
                  </a>
                </li>
              );
            })}
            {otherLangs && currentLanguage && (
            <li>
              <ul className={styles.navigation__langlist}>
                <li
                  key={`${currentLanguage.label}-${currentLanguage.label}`}
                  className={cx(styles.navigation__lang, styles.navigation__langActive, {
                    [styles.navigation__langSticky]: sticky,
                    [styles.navigation__langTransparent]: navTheme === TRANSPARENT,
                    [styles.navigation__langExpanded]: menuExpanded,
                  })}
                >
                  <span>{currentLanguage.label}</span>
                </li>
                {otherLangs.map((lang) => (
                  <li
                    className={cx(styles.navigation__lang, {
                      [styles.navigation__langSticky]: sticky,
                      [styles.navigation__langTransparent]: navTheme === TRANSPARENT,
                      [styles.navigation__langExpanded]: menuExpanded,
                    })}
                    key={`${lang.path}-${lang.label}`}
                  >
                    <a href={makeSourcedLink(lang.path)}>
                      <span>{lang.label}</span>
                    </a>
                  </li>
                ))}
              </ul>
            </li>
            )}
          </AnimateHeight>
        </ul>
      </nav>
    </header>
  );
};

export default Navigation;
