import React from 'react';
import cx from 'classnames';
import { TRANSITION_TEAM } from '@constants';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Cta, InlineLink } from '@atoms';
import { BasicRichText } from '@molecules';

import * as styles from './PrioritiesSection.module.scss';

const PrioritiesSection = (props) => {
  const {
    title, description, link, contentReferences, sectionImagery,
    firstSection, previousSection, backgroundTheme,
  } = props;
  const prevSectType = previousSection && previousSection.sectionType;
  const { linkTitle, ...rest } = link || {};

  const perChunk = 8;
  const contentChunks = contentReferences && contentReferences.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index/perChunk)

    if(!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = []
    }

    resultArray[chunkIndex].push(item)

    return resultArray
  }, []);

  const imageCountLookup = {
    1: 2,
    2: 2,
    3: 4,
    4: 4,
    5: 5,
    6: 4,
    7: 4,
    8: 6,
  }

  let prevImages = 0;

  return (
    <section
      className={cx(styles.prioritiessection, {
          [styles.prioritiessectionFirstSection]: firstSection,
          [styles.prioritiessectionNoTopSection]: prevSectType === TRANSITION_TEAM,
          [styles.prioritiessectionLightpurple]: backgroundTheme === 'lightpurple',
          [styles.prioritiessectionWhite]: backgroundTheme === 'white',
        })}
    >
      <div className={styles.prioritiessection__maxwrap}>
        <div className={styles.prioritiessection__content}>
          <div className={styles.prioritiessection__header}>
            <div className={styles.prioritiessection__titledesc}>
              {title && (
                <h2 className={styles.prioritiessection__title}>{title.title}</h2>
              )}
              {description && (
                <BasicRichText content={description} color="navy" size="small"  />
              )}
            </div>
            {linkTitle && (
              <p><Cta className={styles.prioritiessection__cta} {...rest}>{linkTitle}</Cta></p>
            )}
          </div>
        </div>
      </div>
      {contentChunks && contentChunks.map((chunk, i) => {
        const chunkLength = chunk.length;
        const imgCount = imageCountLookup[chunkLength];
        const useImages = sectionImagery.slice(prevImages,prevImages + imgCount);
        prevImages = prevImages + imgCount;
        return (
          <ul className={cx(styles.prioritiessection__priorities,
            styles[`prioritiessection__priorities${chunkLength}`]
          )}>
            {chunk.map((item, i) => {
              const { title, description, contentful_id, link: cardLink } = item;
              return (
                <li
                  key={contentful_id}
                  className={cx(styles.prioritiessection__priority, styles.prioritiessection__prioritytext)}
                >
                  <div className={styles.prioritiessection__prioritycontent}>
                    <h3 className={styles.prioritiessection__priorityitem}>
                      {title}
                      {" "}
                    </h3>
                    {description && (
                      <BasicRichText content={description} color="navy" size="small"  />
                    )}
                  </div>
                  {cardLink && (
                    <InlineLink {...cardLink} className={styles.prioritiessection__cardlink}>
                      {cardLink.linkTitle}
                    </InlineLink>
                  )}
                </li>
              );
            })}
            {useImages && useImages.map((image, i) => (
                <li className={cx(
                  styles.prioritiessection__priority,
                  styles.prioritiessection__priorityImage,
                  styles[`prioritiessection__priorityImage${i}`]
                )}>
                  <GatsbyImage
                    image={image.gatsbyImageData}
                    className={styles.prioritiessection__image}
                    objectFit="cover"
                    layout="fullWidth"
                    objectPosition="50% 50%"
                    alt={image.description || ""}
                  />
                </li>
              )
            )}
          </ul>
        )
      })}
    </section>
  );
};

export default PrioritiesSection;
