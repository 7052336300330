import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import cx from 'classnames';
import { InlineLink } from '@atoms';

import * as styles  from './NewsListing.module.scss';

const NewsListing = ({ newsItems, sharedLabels }) => {
  const readMore = sharedLabels && sharedLabels.find(label => label.key === "readmore");

  return (
    <div className={styles.newslisting}>
      <div className={styles.newslisting__maxwrap}>
        {newsItems && (
          <ul className={styles.newslisting__allnews}>
            {newsItems.map((item, i) => {
              const { path, pageContent } = item;
              const { title, attribution, publicationDate, previewImage, page, id } = pageContent || {};
              const linkPage = {
                internalPageLink: { path },
                externalLink: path,
              };
              return (
                <li
                  key={id}
                >
                {linkPage && (
                  <InlineLink className={styles.newslisting__newslink} {...linkPage}>
                    <span className={cx(styles.newslisting__newsitem)}>
                      {previewImage && (
                        <GatsbyImage
                          className={styles.newslisting__newsimage}
                          image={previewImage.gatsbyImageData}
                          alt={previewImage.description}
                        />
                      )}
                      <span className={styles.newslisting__newstext}>
                        <span
                          className={cx(styles.newslisting__newstitle, {
                            [styles.newslisting__newstitleNoImage]: !previewImage,
                          })}
                        >
                          {attribution && (
                            <span className={styles.newslisting__newsattribution}>
                              {attribution}
                            </span>
                          )}
                          {title}
                          {publicationDate && (
                            <span className={cx(styles.newslisting__newsdate, styles.newslisting__newsdateMobile)}>
                              <br />
                              {publicationDate}
                            </span>
                          )}
                          <span className={styles.newslisting__readmorespace} />
                          <span className={styles.newslisting__readmore}>
                            {readMore ? readMore.value : "Read more"}
                          </span>
                        </span>
                      </span>
                    </span>
                    {publicationDate && (
                      <span className={cx(styles.newslisting__newsdate, styles.newslisting__newsdateDesktop)}>
                        {publicationDate}
                      </span>
                    )}
                  </InlineLink>
                  )}
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </div>
  );
};

export default NewsListing;
