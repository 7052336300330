// extracted by mini-css-extract-plugin
export var prioritiessection = "PrioritiesSection-module--prioritiessection--2V82t";
export var prioritiessectionLightpurple = "PrioritiesSection-module--prioritiessection--lightpurple--348B4";
export var prioritiessectionWhite = "PrioritiesSection-module--prioritiessection--white--3K1ud";
export var prioritiessectionNoTopSection = "PrioritiesSection-module--prioritiessection--noTopSection--3lOrx";
export var prioritiessectionFirstSection = "PrioritiesSection-module--prioritiessection--firstSection--2yczn";
export var prioritiessection__maxwrap = "PrioritiesSection-module--prioritiessection__maxwrap--2lMOB";
export var prioritiessection__cta = "PrioritiesSection-module--prioritiessection__cta--1NNS9";
export var prioritiessection__content = "PrioritiesSection-module--prioritiessection__content--ax4_o";
export var prioritiessection__title = "PrioritiesSection-module--prioritiessection__title--HVzcR";
export var prioritiessection__titledesc = "PrioritiesSection-module--prioritiessection__titledesc--3ijNd";
export var prioritiessection__header = "PrioritiesSection-module--prioritiessection__header--3ToXL";
export var prioritiessection__cardlink = "PrioritiesSection-module--prioritiessection__cardlink--1Q7BU";
export var prioritiessection__image = "PrioritiesSection-module--prioritiessection__image--1iOoe";
export var prioritiessection__priority = "PrioritiesSection-module--prioritiessection__priority--3YKeh";
export var prioritiessection__priorityitem = "PrioritiesSection-module--prioritiessection__priorityitem--4KMhX";
export var prioritiessection__prioritycontent = "PrioritiesSection-module--prioritiessection__prioritycontent--1-7Tt";
export var prioritiessection__prioritytext = "PrioritiesSection-module--prioritiessection__prioritytext--TUrzZ";
export var prioritiessection__priorities = "PrioritiesSection-module--prioritiessection__priorities--o9VR3";
export var prioritiessection__priorities1 = "PrioritiesSection-module--prioritiessection__priorities--1--3u657";
export var prioritiessection__priorityImage = "PrioritiesSection-module--prioritiessection__priority--image--l0aJa";
export var prioritiessection__priorityImage0 = "PrioritiesSection-module--prioritiessection__priority--image0--fc6eH";
export var prioritiessection__priorityImage1 = "PrioritiesSection-module--prioritiessection__priority--image1--3lNrg";
export var prioritiessection__priorities2 = "PrioritiesSection-module--prioritiessection__priorities--2--3fLCa";
export var prioritiessection__priorities3 = "PrioritiesSection-module--prioritiessection__priorities--3--ShNl2";
export var prioritiessection__priorityImage2 = "PrioritiesSection-module--prioritiessection__priority--image2--1xPOm";
export var prioritiessection__priorities4 = "PrioritiesSection-module--prioritiessection__priorities--4--VHzjn";
export var prioritiessection__priorities5 = "PrioritiesSection-module--prioritiessection__priorities--5--10aiJ";
export var prioritiessection__priorities6 = "PrioritiesSection-module--prioritiessection__priorities--6--3s4nj";
export var prioritiessection__priorityImage3 = "PrioritiesSection-module--prioritiessection__priority--image3--1TVuo";
export var prioritiessection__priorities7 = "PrioritiesSection-module--prioritiessection__priorities--7--3XDew";
export var prioritiessection__priorities8 = "PrioritiesSection-module--prioritiessection__priorities--8--2XA5e";
export var prioritiessection__priorityImage4 = "PrioritiesSection-module--prioritiessection__priority--image4--1dQAe";
export var prioritiessection__priorityImage5 = "PrioritiesSection-module--prioritiessection__priority--image5--1eq_4";
export var slideIn = "PrioritiesSection-module--slideIn--2BXXe";
export var slideInTop = "PrioritiesSection-module--slideInTop--p9-9f";
export var slideInNav = "PrioritiesSection-module--slideInNav--rrm6I";
export var slideInMobileNav = "PrioritiesSection-module--slideInMobileNav--10ZxD";
export var slideOut = "PrioritiesSection-module--slideOut--15wsR";
export var slideOutTop = "PrioritiesSection-module--slideOutTop--1zaqB";
export var fadeOutAnimation = "PrioritiesSection-module--fadeOutAnimation--14L6j";
export var fadeInAnimation = "PrioritiesSection-module--fadeInAnimation--1WaXg";