import React from 'react';
import cx from 'classnames';
import { TRANSITION_TEAM } from '@constants';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Cta } from '@atoms';
import { BasicRichText } from '@molecules';

import * as styles from './BioSection.module.scss';

const BioSection = (props) => {
  const {
    title, description, link, sectionImagery, firstSection, previousSection, backgroundTheme,
  } = props;
  const prevSectType = previousSection && previousSection.sectionType;
  const { linkTitle, ...rest } = link || {};

  return (
    <section
      className={cx(styles.biosection, {
          [styles.biosectionFirstSection]: firstSection,
          [styles.biosectionNoTopSection]: prevSectType === TRANSITION_TEAM,
          [styles.biosectionLightpurple]: backgroundTheme === 'lightpurple',
          [styles.biosectionWhite]: backgroundTheme === 'white',
        })}
    >
      <div className={styles.biosection__maxwrap}>
        {sectionImagery && (
          <div className={styles.biosection__imagewrap}>
            {sectionImagery.map((image, i) => {
              if(i === 0) {
                return(
                  <GatsbyImage
                    image={image.constrained}
                    className={styles.biosection__image}
                    objectFit="cover"
                  />
                )
              } else {
                return <React.Fragment />
              }
            })}
          </div>
        )}
        <div className={styles.biosection__content}>
          {title && (
            <h2 className={styles.biosection__title}>{title.title}</h2>
          )}
          {description && (
            <BasicRichText content={description} color="navy" size="small"  />
          )}
          {linkTitle && (
            <Cta className={styles.biosection__cta} {...rest}>{linkTitle}</Cta>
          )}
        </div>
      </div>
    </section>
  );
};

export default BioSection;
