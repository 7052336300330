import React from 'react';
import cx from 'classnames';
import { BasicRichText } from '@molecules';
import { capitalizeFirstLetter } from '@utils';

import * as styles from './PullQuote.module.scss';

const PullQuote = ({
  cite, quote, pageType,
}) => {
  return (
    <div
      className={cx(styles.pullquote__wrapper, {
        [styles[`pullquote__wrapper${capitalizeFirstLetter(pageType)}`]]: pageType,
      })}
    >
      <BasicRichText content={quote} size="xlarge" color="darkpurple" />
      {cite && (
        <cite className={styles.pullquote__cite}>
          <span className={styles.pullquote__citePurple}>{"—  "}</span>
          {cite}
        </cite>
      )}
    </div>
  );
};

export default PullQuote;
