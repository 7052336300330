// extracted by mini-css-extract-plugin
export var communitysurveysection = "CommunitySurveySection-module--communitysurveysection--20XXf";
export var communitysurveysectionLightpurple = "CommunitySurveySection-module--communitysurveysection--lightpurple--2Ey-d";
export var communitysurveysection__maxwrap = "CommunitySurveySection-module--communitysurveysection__maxwrap--1Fsvh";
export var communitysurveysectionWhite = "CommunitySurveySection-module--communitysurveysection--white--m2ekD";
export var communitysurveysectionFirstSection = "CommunitySurveySection-module--communitysurveysection--firstSection--_Fqak";
export var communitysurveysectionNoTopSection = "CommunitySurveySection-module--communitysurveysection--noTopSection--2_xp0";
export var communitysurveysection__content = "CommunitySurveySection-module--communitysurveysection__content--isdCQ";
export var communitysurveysection__title = "CommunitySurveySection-module--communitysurveysection__title--3U51W";
export var communitysurveysection__cta = "CommunitySurveySection-module--communitysurveysection__cta--pDsnT";
export var communitysurveysection__header = "CommunitySurveySection-module--communitysurveysection__header--1RjIP";
export var communitysurveysection__imagewrap = "CommunitySurveySection-module--communitysurveysection__imagewrap--2liaa";
export var communitysurveysection__image = "CommunitySurveySection-module--communitysurveysection__image--1_e1M";
export var slideIn = "CommunitySurveySection-module--slideIn--2Bfi8";
export var slideInTop = "CommunitySurveySection-module--slideInTop--3stcy";
export var slideInNav = "CommunitySurveySection-module--slideInNav--3LGqy";
export var slideInMobileNav = "CommunitySurveySection-module--slideInMobileNav--2L_HP";
export var slideOut = "CommunitySurveySection-module--slideOut--3l13t";
export var slideOutTop = "CommunitySurveySection-module--slideOutTop--3QYAk";
export var fadeOutAnimation = "CommunitySurveySection-module--fadeOutAnimation--3538T";
export var fadeInAnimation = "CommunitySurveySection-module--fadeInAnimation--2pnI6";