import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { getKeyValue } from '@utils';
import { TRANSPARENT } from '@constants';
import {
  Layout,
} from '@molecules';
import { SimpleHero, Content  } from '@organisms';

const NotFound = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        ...SiteMetadata
      }
      page: contentfulPage(path: { eq: "/404" }) {
        path
        metaTitle
        metaImage {
          file {
            url
          }
        }
        pageContent {
          ... on ContentfulPageContentDetail {
            title
            intro {
              raw
              references {
                ... on ContentfulAsset {
                  contentful_id
                  __typename
                  file {
                    url
                  }
                  title
                }
                ... on ContentfulPage {
                  path
                  contentful_id
                }
              }
            }
          }
        }
      }
      settings: contentfulGlobalSiteSettings(language: { eq: "en-US" }) {
        ...GlobalSiteSettings
      }
    }
  `);

  const {
    site, page, settings, languageLabels,
  } = data || {};

  const locale = 'en-US';
  const { siteTitle } = settings || {};
  const { social } = getKeyValue(site, 'siteMetadata') || {};
  const {
    metaTitle, description, metaImage, path, pageContent,
  } = page || {};
  const {
    intro, title
  } = pageContent || {};
  const { url: metaImageUrl } = getKeyValue(metaImage, 'file') || {};

  return (
    <Layout
      metaDescription={description}
      metaUrl={`https://${process.env.GATSBY_HOST_DOMAIN}${path}`}
      metaTitle={`${metaTitle} | ${siteTitle}`}
      metaImageUrl={metaImageUrl}
      fbAppID={social.fbAppID}
      twitter={social.twitter}
      locale={locale}
      navTheme={TRANSPARENT}
      settings={settings}
      navAnimate
      languageLabels={languageLabels}
      heroLogo="lightblue"
      footerBackground="white"
    >
      <SimpleHero
        title={title}
        heroColor="lightblue"
      />
      <Content
        intro={intro}
        pageType="contentDetail"
      />
    </Layout>
  );
};

export default NotFound;
