import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import cx from 'classnames';
import unionBy from 'lodash.unionby';
import {
  Footer, Navigation, AlertBar,
} from '@organisms';

import { ModalProvider } from '../../../context/ModalContext';
import { FormProvider } from '../../../context/FormContext';
import '../../../styles/init.scss';
import * as styles from './Layout.module.scss';

const Main = ({ id, children }) => (
  <main
    className={cx(styles.layout)}
    id={id}
  >
    {children}
  </main>
);

const Layout = ({
  children, metaDescription, metaUrl, metaTitle, metaImageUrl,
  fbAppID, languageLabels, disableAlertBar, heroLogo,
  twitter, locale, navTheme = 'light', settings, navAnimate,
  pageInOtherLanguages, enLanguagePageLinks, footerBackground,
}) => {
  const finalMetaUrl = metaUrl.endsWith('/') ? metaUrl : `${metaUrl}/`;
  const { enableAlertBar } = settings || {};

  const allpages = (enLanguagePageLinks && enLanguagePageLinks[0] && enLanguagePageLinks[0].pageInOtherLanguages) ? unionBy(pageInOtherLanguages, enLanguagePageLinks[0].pageInOtherLanguages, 'path') : pageInOtherLanguages;
  const allLangs = unionBy(allpages, enLanguagePageLinks, 'path');
  const otherLangs = allLangs.filter(item => item.language !== locale);
  const currentLanguage = { language: locale };

  if (otherLangs && languageLabels && languageLabels.navigationLanguageLabels) {
    otherLangs.forEach((lang, i) => {
      languageLabels.navigationLanguageLabels.forEach((label) => {
        if (label.key === lang.language) {
          otherLangs[i].label = label.value;
        }
        if (label.key === locale) {
          currentLanguage.label = label.value;
        }
      });
    });
  }

  useEffect(() => {
    if (!document || !window) {
      return;
    }

    document.documentElement.style.setProperty('--scrollbar-width', `${window.innerWidth - document.documentElement.clientWidth}px`);
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        {/* General tags */}
        <title>{metaTitle}</title>
        {metaDescription && <meta name="description" content={metaDescription} />}
        {metaImageUrl && <meta name="image" content={`https:${metaImageUrl}`} />}
        <link rel="icon" type="image/png" href="/favicon.ico" sizes="16x16" />

        {/* OpenGraph tags */}
        <meta property="og:url" content={finalMetaUrl} />
        <meta property="og:title" content={metaTitle} />
        {metaDescription && <meta property="og:description" content={metaDescription} />}
        <meta property="og:type" content="website" />
        <meta property="og:locale" content={locale ? locale.substring(0, 2) : 'en'} />
        {metaImageUrl && <meta property="og:image" content={`https:${metaImageUrl}`} />}
        <meta property="fb:app_id" content={fbAppID} />

        {/* Twitter Card tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content={twitter} />
        <meta name="twitter:title" content={metaTitle} />
        {metaDescription && <meta name="twitter:description" content={metaDescription} />}
        {metaImageUrl && <meta name="twitter:image" content={`https:${metaImageUrl}`} />}
        <html lang={locale ? locale.substring(0, 2) : 'en'} />

        {/* Facebook Domain Verification
        <meta name="facebook-domain-verification" content="xxxxxxxxxx" />*/}

        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Organization",
              "@id": "https://allaboardboston.com/#organization",
              "url": "https://allaboardboston.com/",
              "name": "Mayor-Elect Michelle Wu",
              "logo": "https://www.facebook.com/michelleforboston",
              "sameAs": [
                "https://www.facebook.com/michelleforboston",
                "https://twitter.com/wutrain",
                "https://www.youtube.com/user/michelleforboston",
                "https://www.instagram.com/wutrain/"
              ]
            }
          `}
        </script>
      </Helmet>
      <ModalProvider>
        <a
          href="#main-content"
          className={styles.layout__skip}
        >
          Skip to main content
        </a>
        {enableAlertBar && !disableAlertBar && (
          <AlertBar {...settings} />
        )}
        <Navigation
          navTheme={navTheme}
          animate={navAnimate}
          otherLangs={otherLangs}
          currentLanguage={currentLanguage}
          heroLogo={heroLogo}
          {...settings}
        />
        <FormProvider>
          <Main id="main-content">{children}</Main>
        </FormProvider>
        <Footer {...settings} footerBackground={footerBackground} />
      </ModalProvider>
    </React.Fragment>
  );
};

export default Layout;
