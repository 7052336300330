// extracted by mini-css-extract-plugin
export var navigation = "Navigation-module--navigation--2A7O9";
export var navigation__logo = "Navigation-module--navigation__logo--3uVNV";
export var navigation__logoSticky = "Navigation-module--navigation__logo--sticky--J76oF";
export var navigation__mob = "Navigation-module--navigation__mob--1qfAV";
export var navigationDark = "Navigation-module--navigation--dark--1sLQJ";
export var chevron = "Navigation-module--chevron--bUuKw";
export var chevronOne = "Navigation-module--chevron-one--2NPrx";
export var navigationAnimate = "Navigation-module--navigation--animate--3im4M";
export var navigationAnimateInview = "Navigation-module--navigation--animate--inview--TnzYf";
export var navigation__list = "Navigation-module--navigation__list--3aoDK";
export var navigation__listMobile = "Navigation-module--navigation__list--mobile--3xxE6";
export var navigation__listDesktop = "Navigation-module--navigation__list--desktop--i8K4-";
export var navigation__listCollapsed = "Navigation-module--navigation__list--collapsed--2ZG_V";
export var navigation__listSticky = "Navigation-module--navigation__list--sticky--FMm4b";
export var navigation__listLang = "Navigation-module--navigation__list--lang--2qxgC";
export var navigation__listExpanded = "Navigation-module--navigation__list--expanded--WdoSJ";
export var navigation__langTransparent = "Navigation-module--navigation__lang--transparent--3Lwfv";
export var navigation__hamburger = "Navigation-module--navigation__hamburger--AMZSl";
export var navigation__hamburgerSticky = "Navigation-module--navigation__hamburger--sticky--2-YYc";
export var navigation__hamburgerbox = "Navigation-module--navigation__hamburgerbox--2rpqd";
export var navigation__hamburgerinner = "Navigation-module--navigation__hamburgerinner--3eFBM";
export var navigation__item = "Navigation-module--navigation__item--2Nf7p";
export var navigation__itemCta = "Navigation-module--navigation__item--cta--2AAe2";
export var navigation__langlist = "Navigation-module--navigation__langlist--3Wk81";
export var navigation__lang = "Navigation-module--navigation__lang--1VyeY";
export var navigation__langSticky = "Navigation-module--navigation__lang--sticky--gLmY_";
export var navigation__langActive = "Navigation-module--navigation__lang--active--3zWAx";
export var navigation__langExpanded = "Navigation-module--navigation__lang--expanded--3cuYu";
export var navigation__cta = "Navigation-module--navigation__cta--t2Ql_";
export var navigation__itemSticky = "Navigation-module--navigation__item--sticky--3IRVm";
export var header = "Navigation-module--header--2R0_U";
export var headerTransparent = "Navigation-module--header--transparent---bvnz";
export var headerDark = "Navigation-module--header--dark--2q6Mk";
export var headerSticky = "Navigation-module--header--sticky--1i5ey";
export var slideInNav = "Navigation-module--slideInNav--1sYud";
export var headerCollapsed = "Navigation-module--header--collapsed--3RmUb";
export var headerExpanded = "Navigation-module--header--expanded--rN57s";
export var slideIn = "Navigation-module--slideIn--1d0hO";
export var slideInTop = "Navigation-module--slideInTop--384TN";
export var slideInMobileNav = "Navigation-module--slideInMobileNav--1_c87";
export var slideOut = "Navigation-module--slideOut--O5xRF";
export var slideOutTop = "Navigation-module--slideOutTop--1-eKt";
export var fadeOutAnimation = "Navigation-module--fadeOutAnimation--2Oh5s";
export var fadeInAnimation = "Navigation-module--fadeInAnimation--3YiSB";