import React from 'react';
import cx from 'classnames';
import { TRANSITION_TEAM } from '@constants';
import { GatsbyImage } from 'gatsby-plugin-image';
import { InlineLink } from '@atoms';
import { BasicRichText } from '@molecules';
import { capitalizeFirstLetter } from '@utils';

import * as styles from './CallToActionSection.module.scss';

const CallToActionSection = (props) => {
  const {
    title, description, link, sectionImagery, firstSection,  previousSectionColor, previousSection, backgroundTheme,
  } = props;
  const { linkTitle, ...rest } = link || {};
  const prevSectType = previousSection && previousSection.sectionType;

  return (
    <section
      className={cx(styles.calltoactionsection, {
          [styles.calltoactionsectionFirstSection]: firstSection,
          [styles[`calltoactionsection${capitalizeFirstLetter(previousSectionColor)}`]]: previousSectionColor,
          [styles.calltoactionsectionNoTopSection]: prevSectType === TRANSITION_TEAM,
          [styles.calltoactionsectionLightpurple]: backgroundTheme === 'lightpurple',
          [styles.calltoactionsectionWhite]: backgroundTheme === 'white',
        })}
    >
      {sectionImagery && sectionImagery.map((image, i) => {
        if(i === 0) {
          return(
            <GatsbyImage
              image={image.gatsbyImageData}
              className={styles.calltoactionsection__image}
              objectPosition="0% 0%"
            />
          )
        } else {
          return <React.Fragment />;
        }
      })}
      <div className={styles.calltoactionsection__gutters}>
        <div className={styles.calltoactionsection__maxwrap}>
          <div className={styles.calltoactionsection__content}>
            {title && (
              <h2 className={styles.calltoactionsection__title} dangerouslySetInnerHTML={{__html: title.title }} />
            )}
            {linkTitle && (
              <div className={styles.calltoactionsection__text}>
                {description && (
                  <BasicRichText content={description} color="navy" size="small"  />
                )}
                <InlineLink className={styles.calltoactionsection__cta} {...rest}>{linkTitle}</InlineLink>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default CallToActionSection;
