import React from 'react';
import cx from 'classnames';
import { TRANSITION_TEAM } from '@constants';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Cta, InlineLink } from '@atoms';
import { BasicRichText } from '@molecules';

import * as styles from './AnnouncementsSection.module.scss';

const AnnouncementsSection = (props) => {
  const {
    title, description, link, firstSection, contentReferences, previousSection, sharedLabels, backgroundTheme,
  } = props;
  const prevSectType = previousSection && previousSection.sectionType;
  const { linkTitle, ...rest } = link || {};
  const readMore = sharedLabels && sharedLabels.find(label => label.key === "readmore");

  return (
    <section
      className={cx(styles.announcementssection, {
          [styles.announcementssectionFirstSection]: firstSection,
          [styles.announcementssectionNoTopSection]: prevSectType === TRANSITION_TEAM,
          [styles.announcementssectionpurple]: backgroundTheme === 'lightpurple',
          [styles.announcementssectionWhite]: backgroundTheme === 'white',
        })}
    >
      <div className={styles.announcementssection__maxwrap}>
        <div className={styles.announcementssection__header}>
          {title && (
            <h2 className={styles.announcementssection__title} dangerouslySetInnerHTML={{__html: title.title }} />
          )}
          {linkTitle && (
            <div>
              {description && (
                <BasicRichText content={description} color="navy" size="small"  />
              )}
            </div>
          )}
          <Cta {...rest} className={styles.announcementssection__cta}>{linkTitle}</Cta>
        </div>
        {contentReferences && (
          <ul className={styles.announcementssection__allnews}>
            {contentReferences.map((item, i) => {
              const { title, attribution, publicationDate, previewImage, page, id } = item;
              const linkPage = page && page[0]
              return (
                <li
                  key={id}
                >
                {linkPage && (
                  <InlineLink className={styles.announcementssection__newslink} internalPageLink={linkPage}>
                    <span className={cx(styles.announcementssection__newsitem)}>
                      {previewImage && (
                        <GatsbyImage
                          className={styles.announcementssection__newsimage}
                          image={previewImage.gatsbyImageData}
                          alt={previewImage.description}
                        />
                      )}
                      <span className={styles.announcementssection__newstext}>
                        <span
                          className={cx(styles.announcementssection__newstitle, {
                            [styles.announcementssection__newstitleNoImage]: !previewImage,
                          })}
                        >
                          {attribution && (
                            <span className={styles.announcementssection__newsattribution}>
                              {attribution}
                            </span>
                          )}
                          {title}
                          {publicationDate && (
                            <span className={cx(styles.announcementssection__newsdate, styles.announcementssection__newsdateMobile)}>
                              <br />
                              {publicationDate}
                            </span>
                          )}
                          <span className={styles.announcementssection__readmorespace} />
                          <span className={styles.announcementssection__readmore}>
                            {readMore ? readMore.value : "Read more"}
                          </span>
                        </span>
                      </span>
                    </span>
                    {publicationDate && (
                      <span className={cx(styles.announcementssection__newsdate, styles.announcementssection__newsdateDesktop)}>
                        {publicationDate}
                      </span>
                    )}
                  </InlineLink>
                  )}
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </section>
  );
};

export default AnnouncementsSection;
