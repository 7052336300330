// extracted by mini-css-extract-plugin
export var appointee = "Appointee-module--appointee--3Feot";
export var appointeeActive = "Appointee-module--appointee--active--3Sj3L";
export var appointee__bg = "Appointee-module--appointee__bg--2NbLm";
export var appointee__name = "Appointee-module--appointee__name--1syTe";
export var appointee__title = "Appointee-module--appointee__title--25kqB";
export var appointee__image = "Appointee-module--appointee__image--2KG6V";
export var appointee__group = "Appointee-module--appointee__group--3l111";
export var slideIn = "Appointee-module--slideIn--WFtmX";
export var slideInTop = "Appointee-module--slideInTop--3Twct";
export var slideInNav = "Appointee-module--slideInNav--1OOa7";
export var slideInMobileNav = "Appointee-module--slideInMobileNav--3dPr6";
export var slideOut = "Appointee-module--slideOut--1AETx";
export var slideOutTop = "Appointee-module--slideOutTop--2LDzh";
export var fadeOutAnimation = "Appointee-module--fadeOutAnimation--1A-cH";
export var fadeInAnimation = "Appointee-module--fadeInAnimation--1qz6j";