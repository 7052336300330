import React, {
  useState, useRef, useEffect, useContext,
} from 'react';
import {
  Formik, Field, Form, ErrorMessage,
} from 'formik';
import cx from 'classnames';
import AnimateHeight from 'react-animate-height';
import { BasicRichText } from '@molecules';
import { useFade } from '@hooks';
import { capitalizeFirstLetter } from '@utils';

import FormContext from '../../../context/FormContext';
import { formValidators } from './validation';
import * as styles from './Form.module.scss';

const getAsField = (fieldType) => {
  let asField;
  switch (fieldType) {
    case 'select':
      asField = 'select';
      break;
    case 'textarea':
      asField = 'textarea';
      break;
    case 'file':
      asField = 'input';
      break;
    default:
      asField = 'input';
  }
  return asField;
};

const FormWrapper = ({
  contentfulfields, submitButtonLabel, id, postSubmitMessage,
  formTheme = 'dark', postUrl, legalDisclaimer,
  submitButtonDesktopWidth, pageType, submitButtonMobileWidth,
  sharedLabels,
}) => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const formRef = useRef();
  const [isFading, hasFaded] = useFade(formSubmitted, 500);
  const { submitted, toggleSubmitted } = useContext(FormContext);
  const [fsclient, setFsClient] = useState();
  const optionalObj = sharedLabels && sharedLabels.find(label => label.key === "optional");
  const optional = optionalObj ? optionalObj.value : "Optional";

  useEffect(() => {
    if(typeof window !== "undefined" && !fsclient) {
      setFsClient(window.filestack.init(process.env.GATSBY_FSK_API_KEY, {
        security: {
          policy: process.env.GATSBY_FSK_POLICY,
          signature: process.env.GATSBY_FSK_SIGN,
        }
      }));
    }
  }, [fsclient]);

  useEffect(() => {
    if (hasFaded) {
      const timer = setTimeout(() => {
        if (formRef.current) {
          const { offsetTop, offsetHeight } = formRef.current;
          window.scrollBy({
            top: -(offsetTop + offsetHeight),
            left: 0,
            behavior: 'smooth',
          });
        }
      }, 550);
      return () => clearTimeout(timer);
    }
    return () => {};
  }, [hasFaded]);

  useEffect(() => {
    if (hasFaded) {
      const timer = setTimeout(() => {
        if (formRef.current) {
          const { offsetTop, offsetHeight } = formRef.current;
          window.scrollBy({
            top: -(offsetTop + offsetHeight),
            left: 0,
            behavior: 'smooth',
          });
        }
      }, 550);
      return () => clearTimeout(timer);
    }
    return () => {};
  }, [hasFaded]);

  if (!contentfulfields || !postUrl) {
    return <React.Fragment />;
  }

  const initialValues = {};
  contentfulfields.forEach((field) => {
    initialValues[field.name] = '';
  });

  return (
    <div
      ref={formRef}
      className={cx({
        [styles[`form${capitalizeFirstLetter(formTheme)}`]]: formTheme,
      })}
    >
      <AnimateHeight
        duration={500}
        height={(hasFaded || isFading) ? 0 : 'auto'}
        aria-hidden={hasFaded}
      >
        <div
          id={id}
          className={cx(styles.form__outerWrapper, {
            [styles[`form__outerWrapper${capitalizeFirstLetter(pageType)}`]]: pageType,
          })}
        >
          <Formik
            initialValues={initialValues}
            onSubmit={async (values, actions) => {
              const formValues = { ...values };
              let data = new FormData();
              let fileFields = [];

              if (formValues) {
                Object.keys(formValues).forEach((key) => {
                  if(!(values[key] instanceof File)) {
                    data.append(key, values[key]);
                  } else {
                    fileFields.push({value: values[key], key})
                  }
                });
              }
              const fileFieldCount = fileFields.length;

              if(fileFieldCount > 0) {
                fileFields.forEach((field, i) => {
                  fsclient.upload(field.value)
                    .then(res => {
                      if(res.url) {
                        data.append(`${field.key}-url`, res.url);
                        data.append(`${field.key}-filename`, res.filename);
                        data.append(`${field.key}-filekey`, res.handle);
                      }
                      console.log('success: ', res)
                      if(i+1 === fileFieldCount) {
                        fetch(postUrl, {
                          method: 'POST',
                          body: data,
                        })
                          .then((resp) => {
                            if (!resp.ok) {
                              console.error(resp);
                            } else {
                              actions.resetForm();
                            }
                          })
                          .catch((error) => {
                            console.error(error);
                          })
                          .finally(() => {
                            setFormSubmitted(true);
                            actions.setSubmitting(false);
                            toggleSubmitted({
                              ...submitted,
                              [id]: true,
                            });
                          });
                      }
                    })
                    .catch(err => {
                      console.log(err)
                    });
                })
              } else {
                fetch(postUrl, {
                  method: 'POST',
                  body: data,
                })
                  .then((resp) => {
                    if (!resp.ok) {
                      console.error(resp);
                    } else {
                      actions.resetForm();
                    }
                  })
                  .catch((error) => {
                    console.error(error);
                  })
                  .finally(() => {
                    setFormSubmitted(true);
                    actions.setSubmitting(false);
                    toggleSubmitted({
                      ...submitted,
                      [id]: true,
                    });
                  });
              }
            }}
            validate={() => ({})}
          >
            {({ errors, touched, setFieldValue, }) => (
              <Form
                className={cx(styles.form, {
                  [styles.formIsFading]: isFading,
                })}
                enctype="multipart/form-data"
              >
                {contentfulfields.map((field) => {
                  const {
                    type, name, desktopWidth, mobileWidth, required, id: fieldId,
                    label, errorMessage, placeholder, options, helpText, characterLimit,
                  } = field;
                  const asField = getAsField(type);
                  const validationType = required ? 'required' : 'optional';
                  const validationFunction = formValidators[type]
                  && formValidators[type][validationType];
                  const hasError = errors[name] && touched[name];

                  if (type === 'email' || type === 'text' || type === 'tel' || type === 'textarea' || type === 'postalcode' || type === 'number') {
                    return (
                      <React.Fragment>
                        <div
                          key={fieldId}
                          className={cx(styles.form__fieldWrapper, {
                            [styles[`form__fieldWrapperDesktop${desktopWidth.replace(/%/g,'')}`]]: desktopWidth,
                            [styles[`form__fieldWrapperMobile${mobileWidth.replace(/%/g,'')}`]]: mobileWidth,
                          })}
                        >
                          <label
                            htmlFor={fieldId}
                            className={cx(styles.form__label, {
                              [styles[`form__label${capitalizeFirstLetter(formTheme)}`]]: formTheme,
                            })}
                          >
                            {label}
                            {!required && (
                              <span className={styles.form__required}>{` (${optional ? optional : "optional"})`}</span>
                            )}
                            {helpText && (
                              <span className={styles.form__help}>{helpText}</span>
                            )}
                          </label>
                          <Field
                            id={fieldId}
                            name={name}
                            type={type}
                            as={asField}
                            validate={(value) => {
                              if (typeof validationFunction === 'function') {
                                return validationFunction(value, label, errorMessage, characterLimit);
                              }
                              return '';
                            }}
                            aria-describedby={`${fieldId}-error`}
                            className={cx(styles[`form__field${capitalizeFirstLetter(asField)}`], {
                              [styles[`form__field${capitalizeFirstLetter(asField)}HasError`]]: hasError,
                            })}
                          />
                          <span
                            className={styles.form__errorMessage}
                            aria-live="assertive"
                            id={`${fieldId}-error`}
                          >
                            <ErrorMessage name={name} />
                          </span>
                        </div>
                      </React.Fragment>
                    );
                  }
                  if(type === 'file') {
                    return (
                      <React.Fragment>
                        <div
                          key={fieldId}
                          className={cx(styles.form__fieldWrapper, {
                            [styles[`form__fieldWrapperDesktop${desktopWidth.replace(/%/g,'')}`]]: desktopWidth,
                            [styles[`form__fieldWrapperMobile${mobileWidth.replace(/%/g,'')}`]]: mobileWidth,
                          })}
                        >
                          <label
                            htmlFor={fieldId}
                            className={cx(styles.form__label, {
                              [styles[`form__label${capitalizeFirstLetter(formTheme)}`]]: formTheme,
                            })}
                          >
                            {label}
                            {!required && (
                              <span className={styles.form__required}>{` (${optional ? optional : "optional"})`}</span>
                            )}
                            {helpText && (
                              <span className={styles.form__help}>{helpText}</span>
                            )}
                          </label>
                          <Field
                            id={fieldId}
                            name={name}
                            type={type}
                            as={asField}
                            onChange={(e) => {
                              setFieldValue(name, e.target.files[0]);
                            }}
                            validate={(value) => {
                              if (typeof validationFunction === 'function') {
                                return validationFunction(value, label, errorMessage);
                              }
                              return '';
                            }}
                            aria-describedby={`${fieldId}-error`}
                            className={cx(styles[`form__fieldFile`], {
                              [styles[`form__fieldFileHasError`]]: hasError,
                            })}
                            value={undefined}
                          />
                          <span
                            className={styles.form__errorMessage}
                            aria-live="assertive"
                            id={`${fieldId}-error`}
                          >
                            <ErrorMessage name={name} />
                          </span>
                        </div>
                      </React.Fragment>
                    );
                  }
                  if (type === 'select') {
                    return (
                      <div
                        className={cx(styles.form__fieldWrapper, styles[`form__fieldWrapper${capitalizeFirstLetter(asField)}`], {
                          [styles.form__fieldWrapperHasError]: hasError,
                          [styles[`form__fieldWrapperDesktop${desktopWidth.replace(/%/g,'')}`]]: desktopWidth,
                          [styles[`form__fieldWrapperMobile${mobileWidth.replace(/%/g,'')}`]]: mobileWidth,
                        })}
                        key={fieldId}
                      >
                        <label
                          className={cx(styles.form__label, {
                            [styles[`form__label${capitalizeFirstLetter(formTheme)}`]]: formTheme,
                          })}
                          htmlFor={fieldId}
                        >
                          {label}
                          {!required && (
                            <span className={styles.form__required}>{` (${optional ? optional : "optional"})`}</span>
                          )}
                          {helpText && (
                            <span className={styles.form__help}>{helpText}</span>
                          )}
                        </label>
                        <Field
                          id={fieldId}
                          name={name}
                          type={type}
                          as={asField}
                          aria-describedby={`${fieldId}-error`}
                          className={cx(styles[`form__field${capitalizeFirstLetter(asField)}`], {
                            [styles[`form__field${capitalizeFirstLetter(asField)}HasError`]]: hasError,
                          })}
                          validate={(value) => {
                            if (typeof validationFunction === 'function') {
                              return validationFunction(value, label, errorMessage);
                            }
                            return '';
                          }}
                        >
                          <option value="none">{placeholder || 'Select an option'}</option>
                          {options
                          && options.map((option) => (
                            <option value={option.value}>{option.key}</option>
                          ))}
                        </Field>
                        <span
                          className={styles.form__errorMessage}
                          aria-live="assertive"
                          id={`${fieldId}-error`}
                        >
                          <ErrorMessage name={name} />
                        </span>
                      </div>
                    );
                  }

                  if (type === 'checkbox') {
                    return (
                      <div
                        key={fieldId}
                        role="group"
                        className={cx(styles.form__fieldWrapper, {
                          [styles[`form__fieldWrapperDesktop${desktopWidth.replace(/%/g,'')}`]]: desktopWidth,
                          [styles[`form__fieldWrapperMobile${mobileWidth.replace(/%/g,'')}`]]: mobileWidth,
                        })}
                      >
                        <label
                          className={cx(styles.form__label,
                            `field-group-label-${type}`, {
                              [styles[`form__label${capitalizeFirstLetter(formTheme)}`]]: formTheme,
                              [styles.form__labelHasError]: hasError,
                            })}
                          htmlFor={fieldId}
                        >
                          {label}
                          {!required && (
                            <span className={styles.form__required}>{` (${optional ? optional : "optional"})`}</span>
                          )}
                          {helpText && (
                            <span className={styles.form__help}>{helpText}</span>
                          )}
                        </label>
                        {options && options.map((option) => (
                          <label
                            htmlFor={`${fieldId}-${option.value}`}
                            className={cx(styles.form__checkboxLabel,
                              `${styles.form__label}-${field.type}`, {
                                [`${styles.form__label}-${field.type}HasError`]: hasError,
                                [styles[`form__label${capitalizeFirstLetter(formTheme)}`]]: formTheme,
                              })}
                          >
                            {option.key}
                            <Field
                              type="checkbox"
                              name={name}
                              value={option.value}
                              className={cx(styles[`form__field${capitalizeFirstLetter(type)}`], {
                                [styles[`form__field${capitalizeFirstLetter(type)}HasError`]]: hasError,
                              })}
                              as={asField}
                              validate={(value) => {
                                if (typeof validationFunction === 'function') {
                                  return validationFunction(value, label, errorMessage);
                                }
                                return '';
                              }}
                              aria-describedby={`${fieldId}-error`}
                            />
                            <span className={styles.form__checkmark} />
                          </label>
                        ))}
                        <span
                          className={styles.form__errorMessage}
                          aria-live="assertive"
                          id={`${fieldId}-error`}
                        >
                          <ErrorMessage name={name} />
                        </span>
                      </div>
                    );
                  }

                  return (
                    <div
                      key={fieldId}
                      className={cx(styles.form__fieldWrapper, {
                        [styles[`form__fieldWrapperDesktop${desktopWidth.replace(/%/g,'')}`]]: desktopWidth,
                        [styles[`form__fieldWrapperMobile${mobileWidth.replace(/%/g,'')}`]]: mobileWidth,
                      })}
                    >
                      <label
                        htmlFor={fieldId}
                        className={cx(styles.form__label, {
                          [styles[`form__label${capitalizeFirstLetter(formTheme)}`]]: formTheme,
                        })}
                      >
                        {label}
                        {helpText && (
                          <span className={styles.form__help}>{helpText}</span>
                        )}
                      </label>
                      <Field
                        id={fieldId}
                        className={cx(styles[`form__field${capitalizeFirstLetter(asField)}`], {
                          [styles[`form__field${capitalizeFirstLetter(asField)}HasError`]]: hasError,
                        })}
                        name={name}
                        type={type}
                        as={asField}
                      />
                    </div>
                  );
                })}
                <div
                  className={cx(styles.form__fieldWrapper,
                    styles.form__fieldWrapperSubmit,
                    styles.form__fieldWrapperMobile100,
                    {
                      [styles[`form__fieldWrapperDesktop${submitButtonDesktopWidth.replace(/%/g,'')}`]]: submitButtonDesktopWidth,
                      [styles[`form__fieldWrapperMobile${submitButtonMobileWidth.replace(/%/g,'')}`]]: submitButtonMobileWidth,
                    })}
                >
                  <button
                    className={cx(styles.form__submit, {
                      [styles[`form__submit${capitalizeFirstLetter(pageType)}`]]: pageType,
                    })}
                    type="submit"
                  >
                    {submitButtonLabel || 'Submit'}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
          {legalDisclaimer && (
            <div className={styles.form__legal}>
              <hr />
              <BasicRichText content={legalDisclaimer} size="small" color="navy" />
            </div>
          )}
        </div>
      </AnimateHeight>
      {hasFaded && (
        <React.Fragment>
          {postSubmitMessage && (
            <div className={styles.form__thankyou}>
              <BasicRichText content={postSubmitMessage} size="medium" color="navy" />
            </div>
          )}
        </React.Fragment>
      )}
    </div>
  );
};

export default FormWrapper;
