import { PhoneNumberUtil } from 'google-libphonenumber';

const phoneUtil = PhoneNumberUtil.getInstance();
const emailRegex = new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i);
const zipCodeRegex = new RegExp(/(^\d{5}$)|(^\d{5}-\d{4}$)/i);

const validateRequired = (value, customError) => {
  let error;
  if (!value) {
    error = customError || 'Required';
  }
  return error;
};

const noValidation = (value, text, errorMsg, charLimit) => {
  if(charLimit && value) {
    return value.length > charLimit && (`Sorry, you cannot use more that ${charLimit} characters.`)
  }
  return undefined;
}

export const validateEmailRequired = (value) => {
  let error = validateRequired(value, 'Please enter an email address.');
  if (!error && !emailRegex.test(value)) {
    error = 'Invalid email address';
  }
  return error;
};

export const validateEmail = (value) => {
  let error;
  if (value && !emailRegex.test(value)) {
    error = 'Invalid email address';
  }
  return error;
};

export const validateZipRequired = (value) => {
  let error = validateRequired(value, 'Please enter a ZIP code.');
  if (!error && !zipCodeRegex.test(value)) {
    error = 'Invalid ZIP code';
  }
  return error;
};

export const validateZip = (value) => {
  let error;
  if (value && !zipCodeRegex.test(value)) {
    error = 'Invalid ZIP code';
  }
  return error;
};

export const validatePhoneNumber = (value) => {
  let error;
  if (value) {
    try {
      const phoneNumber = phoneUtil.parse(value, 'US');
      if (!phoneUtil.isValidNumberForRegion(phoneNumber, 'US')) {
        error = 'Please enter a valid phone number.';
      }
    } catch (e) {
      error = 'Please enter a valid phone number.';
    }
  }
  return error;
};

export const validatePhoneNumberRequired = (value) => {
  let error = validateRequired(value, 'Please enter a phone number.');
  try {
    const phoneNumber = phoneUtil.parse(value, 'US');
    if (!phoneUtil.isValidNumberForRegion(phoneNumber, 'US')) {
      error = 'Please enter a valid phone number.';
    }
  } catch (e) {
    error = 'Please enter a valid phone number.';
  }
  return error;
};

export const validateTextRequired = (value, text, errorMsg, charLimit) => {
  let customError;
  if(charLimit && value) {
    customError = value.length > charLimit && (`Sorry, you cannot use more that ${charLimit} characters.`);
    return customError;
  } else {
    customError = errorMsg || (text && `Please provide a ${text.toLowerCase()}.`);
  }
  const error = validateRequired(value, customError);
  return error;
};


export const validateNumberRequired = (value, text, errorMsg) => {
  const customError = errorMsg || (text && `Please provide a ${text.toLowerCase()}.`);
  const error = (!value || value === 0) ? customError : '';
  return error;
};

export const validateFileRequired = (value, text, errorMsg) => {
  const fileType = value && value.type;
  const fileSize = value && value.size;
  let customError;
  let error;
  if(fileType !== "application/pdf" && fileType !== "application/msword" & fileType !== "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
    error = 'Please provide a \'.pdf\', \'.doc\', or \'.docx\' file type.';
  } else if (fileSize >= 500000) {
    error = 'Please provide a  file under 500Kb.';
  } else {
    customError = errorMsg || (text && `Please provide a ${text.toLowerCase()}.`);
    error = validateRequired(value, customError);
  }
  return error;
};

export const validateCheckboxRequired = (value, text, errorMsg) => {
  const customError = errorMsg || (text && 'Please check a box.');
  const error = value && value.length > 0 ? '' : customError;
  return error;
};

export const validateSelectRequired = (value, text, errorMsg) => {
  const customError = errorMsg || (text && 'Please select an option.');
  const error = value && value !== 'none' ? '' : customError;
  return error;
};

export const formValidators = {
  email: {
    required: validateEmailRequired,
    optional: validateEmail,
  },
  tel: {
    required: validatePhoneNumberRequired,
    optional: validatePhoneNumber,
  },
  postalcode: {
    required: validateZipRequired,
    optional: validateZip,
  },
  text: {
    required: validateTextRequired,
    optional: noValidation,
  },
  file: {
    required: validateFileRequired,
    optional: noValidation,
  },
  textarea: {
    required: validateTextRequired,
    optional: noValidation,
  },
  checkbox: {
    required: validateCheckboxRequired,
  },
  select: {
    required: validateSelectRequired,
    optional: noValidation,
  },
  number: {
    required: validateNumberRequired,
    optional: noValidation,
  },
};
