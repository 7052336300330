// extracted by mini-css-extract-plugin
export var announcementssection = "AnnouncementsSection-module--announcementssection--L60re";
export var announcementssectionLightpurple = "AnnouncementsSection-module--announcementssection--lightpurple--33kKs";
export var announcementssectionWhite = "AnnouncementsSection-module--announcementssection--white--18s7E";
export var announcementssectionNoTopSection = "AnnouncementsSection-module--announcementssection--noTopSection--kn8FX";
export var announcementssection__maxwrap = "AnnouncementsSection-module--announcementssection__maxwrap--3eJOO";
export var announcementssectionFirstSection = "AnnouncementsSection-module--announcementssection--firstSection--2bZGJ";
export var announcementssection__header = "AnnouncementsSection-module--announcementssection__header--1uPoE";
export var announcementssection__title = "AnnouncementsSection-module--announcementssection__title--3lE99";
export var announcementssection__allnews = "AnnouncementsSection-module--announcementssection__allnews--3z7Q9";
export var announcementssection__newslink = "AnnouncementsSection-module--announcementssection__newslink--2LI5J";
export var announcementssection__cta = "AnnouncementsSection-module--announcementssection__cta--24Q1J";
export var announcementssection__newsitem = "AnnouncementsSection-module--announcementssection__newsitem--OKgdS";
export var announcementssection__newstext = "AnnouncementsSection-module--announcementssection__newstext--3tYJx";
export var announcementssection__newsimage = "AnnouncementsSection-module--announcementssection__newsimage--1powy";
export var announcementssection__newsattribution = "AnnouncementsSection-module--announcementssection__newsattribution--2y8S3";
export var announcementssection__newsdate = "AnnouncementsSection-module--announcementssection__newsdate--1nHAD";
export var announcementssection__newsdateDesktop = "AnnouncementsSection-module--announcementssection__newsdate--desktop--6W97q";
export var announcementssection__newsdateMobile = "AnnouncementsSection-module--announcementssection__newsdate--mobile--1SOAv";
export var announcementssection__readmore = "AnnouncementsSection-module--announcementssection__readmore--36HhI";
export var announcementssection__readmorespace = "AnnouncementsSection-module--announcementssection__readmorespace--1huK8";
export var announcementssection__newstitle = "AnnouncementsSection-module--announcementssection__newstitle--RhfjN";
export var slideIn = "AnnouncementsSection-module--slideIn--2GHuQ";
export var slideInTop = "AnnouncementsSection-module--slideInTop--38cDP";
export var slideInNav = "AnnouncementsSection-module--slideInNav--9gd7O";
export var slideInMobileNav = "AnnouncementsSection-module--slideInMobileNav--2fTgA";
export var slideOut = "AnnouncementsSection-module--slideOut--2RJdh";
export var slideOutTop = "AnnouncementsSection-module--slideOutTop--2FeUS";
export var fadeOutAnimation = "AnnouncementsSection-module--fadeOutAnimation--CvkMM";
export var fadeInAnimation = "AnnouncementsSection-module--fadeInAnimation--2G4V1";