// extracted by mini-css-extract-plugin
export var newslisting = "NewsListing-module--newslisting--3v5if";
export var newslisting__maxwrap = "NewsListing-module--newslisting__maxwrap--2QgmZ";
export var newslisting__allnews = "NewsListing-module--newslisting__allnews--2Wu0q";
export var newslisting__newslink = "NewsListing-module--newslisting__newslink--3Xqgb";
export var newslisting__cta = "NewsListing-module--newslisting__cta--dIqbN";
export var newslisting__newsitem = "NewsListing-module--newslisting__newsitem--3o02B";
export var newslisting__newstext = "NewsListing-module--newslisting__newstext--27QSE";
export var newslisting__newsimage = "NewsListing-module--newslisting__newsimage--s8ZUZ";
export var newslisting__newsattribution = "NewsListing-module--newslisting__newsattribution--1XOYS";
export var newslisting__newsdate = "NewsListing-module--newslisting__newsdate--2vn-f";
export var newslisting__newsdateDesktop = "NewsListing-module--newslisting__newsdate--desktop--1wV-f";
export var newslisting__newsdateMobile = "NewsListing-module--newslisting__newsdate--mobile--Jc-Eq";
export var newslisting__readmore = "NewsListing-module--newslisting__readmore--3SITG";
export var newslisting__readmorespace = "NewsListing-module--newslisting__readmorespace--2II3D";
export var newslisting__newstitle = "NewsListing-module--newslisting__newstitle--1oDPu";
export var slideIn = "NewsListing-module--slideIn--Tnkw1";
export var slideInTop = "NewsListing-module--slideInTop--11VQB";
export var slideInNav = "NewsListing-module--slideInNav--1GemP";
export var slideInMobileNav = "NewsListing-module--slideInMobileNav--xS3Xu";
export var slideOut = "NewsListing-module--slideOut--XDQMD";
export var slideOutTop = "NewsListing-module--slideOutTop--6Hi1w";
export var fadeOutAnimation = "NewsListing-module--fadeOutAnimation--2Bdeo";
export var fadeInAnimation = "NewsListing-module--fadeInAnimation--wpycO";